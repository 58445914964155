@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.header {
	margin-bottom: .75rem;

	&Row {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: .5rem;
		}
	}

	&Title {
		margin-right: auto;
		@include f1-400();
		font-size: 18/16+rem;
		line-height: 22/18+em;
		color: #000;
	}

	&Add {
		padding: 0 1rem;
		min-width: 0;
	}

	&More {
		margin-right: -10/16+rem;

		svg {
			transform: rotate(90deg);
		}
	}

	&Text {
		font-size: 13/16+rem;
		line-height: 16/13+em;
		color: $dusty-gray;
	}
}

.title {
	@include f1-400();
	font-size: 18/16+rem;
	line-height: 22/18+em;
	color: #000;
}

.barChart {
	height: 200/16+rem;
}