@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.up {
	margin: 0 -1rem .75rem;
	padding: 0 1rem 1rem;
	background: linear-gradient(-45deg, #2A5671, #13193E);
	box-shadow: 0 .125rem .25rem 0 rgba(#000,.5);
}

.header {
	display: flex;
	align-items: center;
	padding: 11/16+rem 0;
}

.back {
	margin-left: -10/16+rem;
	transform: translateY(.125rem);
	line-height: 0;

	path {

		&:first-child {
			fill: #fff;
		}
	}
}

.title {
	margin-right: .5rem;
	@include f1-700();
	color: #fff;
}

.publisherName {
	margin-bottom: 1.5rem;
}

.overview {

	&Header {
		display: flex;
		margin: 0 0 2rem;
	}

	&Title {
		@include f1-700();
		font-size: 18/16+rem;
		line-height: 22/18+em;
		color: #000;
	}

	&Select {
		margin-bottom: 20/16+rem;
	}
}

.stats {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-left: auto;
	font-size: 11/16+rem;
	line-height: 13/11+em;
	color: $dove-gray-1;

	&Item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 .5rem;

		h4 {
			margin-bottom: 2/16+rem;
			@include f1-400();
			font-size: 11/16+rem;
			line-height: 13/11+em;
			color: $dove-gray-1;
		}

		span {
			@include f1-700();
		}
	}
}

.barChart {
	margin-bottom: 60/16+rem;
}

.footer {
	padding: 20/16+rem 0 1rem;
	border-top: 1/16+rem solid $dusty-gray;
}

.buttons {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
}