@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.rightSidebar {
	flex-wrap: wrap;
}

.switch {

	&:global {

		&.switch {
			margin: .5rem auto 0;
		}
	}
}

.header {
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	margin: 0 -1rem 1rem;
	padding: 1rem .5rem;
	overflow: hidden;
	background: linear-gradient(-45deg, #2A5671, #13193E);

	&Btn {
		flex: 0 0 auto;
		padding: 0 1.5rem;
		min-width: 0;
	}
}

.publisherName {
	flex: 0 1 auto;
	padding-right: .5rem;
	margin-right: auto;
}

.select {
	margin-bottom: 1.5rem;
}

.list {
	flex: 1 1 auto;
	overflow: auto;
}

.item {

	&:not(:last-child) {
		margin-bottom: .75rem;
	}
}