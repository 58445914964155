@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.list {

	&:not(:last-child) {
		margin-bottom: 20/16+rem;
	}
}

.radio {

	&:not(:last-child) {
		margin-bottom: .75rem;
	}

	&Label {
		display: flex;
		align-items: center;

		i {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 10/16+rem;
			width: 20/16+rem;
			height: 14/16+rem;

			svg {
				height: 100%;
				width: auto;
			}
		}
	}

	&Image {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10/16+rem;
		width: 20/16+rem;

		img {
			max-width: 100%;
		}
	}

	&Info {

	}

	&Title {
		@include f1-700();
		font-size: .5rem;
		line-height: 10/8+em;
		color: #000;
	}

	&Description {

		p {
			font-size: .5rem;
			line-height: 10/8+em;
			color: #000;
		}
	}
}