@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.header {
	display: flex;
	align-items: center;
}

.methods {
	position: relative;
	margin: 0 -1rem .75rem;
	padding: 1rem;
	background: $wild-sand;

	&List {
		display: flex;
		align-items: stretch;
		margin: 0 -.5rem;
	}
}

.method {
	flex: 1 1 33.333%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 .5rem;
	padding: .25rem;
	min-height: 43/16+rem;
	border-radius: .25rem;
	border: 1/16+rem solid $dusty-gray;
	cursor: pointer;
	
	&:global {
		
		&._active {
			border: none;
			background: linear-gradient(-135deg, #3BA394, #30717E 25%, #13193E);

			i {

				path,
				polygon {
					fill: #fff;
				}
			}

			:local {

				.method {

					&Title {
						color: #fff;
					}
				}
			}
		}
	}

	i {
		width: auto;
		height: 20/16+rem;

		svg {
			height: 100%;
			width: auto;
		}
	}

	&Title {
		margin-left: .5rem;
		@include f1-400();
		font-size: .75rem;
		line-height: 15/12+em;
		color: $mine-shaft-1;
	}

	&Image {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.label {
	@include f1-400();
	font-size: 11/16+rem;
	line-height: 13/11+em;
	color: $dove-gray-1;
}

.tumbler {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.cardNumber {
	position: relative;

	i {
		position: absolute;
		bottom: .125rem;
		right: 0;
		display: block;
		width: 29/16+rem;
		height: auto;
		line-height: 0;
	}
}