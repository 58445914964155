@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.header {
	display: flex;
	align-items: center;

	&Select {

		&:global {

			&.select {
				margin-left: 1rem;
				width: 10rem;
			}
		}
	}
}

.close {
	margin-right: 1rem;
}

.title {
	margin-right: auto;
	white-space: nowrap;
}