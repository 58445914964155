@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.types {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.type {
	@extend %button;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 1rem;
	height: 30/16+rem;
	border-radius: 15/16+rem;
	border: 1/16+rem solid transparent;
	cursor: pointer;
	@include f1-400();
	font-size: 15/16+rem;
	line-height: 18/15+em;
	color: $alto-1;
	text-align: center;

	&:global {

		&._active {
			border-color: #13193E;
			background: #fff;
			color: $mine-shaft-1;
		}
	}

	&:focus {
		outline: none;
	}
}