@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	padding: 1rem;
	background: #fff;
	box-shadow: 0 .125rem .25rem 0 rgba(#000,.5);
}

.header {
	display: flex;
	align-items: flex-start;

	&:not(:last-child) {
		margin-bottom: .75rem;
	}
}

.sum {
	margin-left: auto;

	&Value {
		font-size: 17/16+rem;
		line-height: 21/17+em;
		color: #000;
	}

	&Credit {
		font-size: 13/16+rem;
		line-height: 16/13+em;
		color: $gray;
	}
}

.row {
	display: flex;
	align-items: center;
}

.amount {
	margin-right: .25rem;
	width: 140/16+rem;
}

.method {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-left: auto;
	min-width: 120/16+rem;
	text-align: right;

	p,
	&Btn {
		@include f1-400();
		font-size: .5rem;
		line-height: 10/8+em;
		color: $mine-shaft-1;
		text-transform: uppercase;
	}

	&Btn {
		@extend %button;
		display: flex;
		align-items: center;
		cursor: pointer;
		color: $malibu;

		svg {
			margin-left: .125rem;
			height: auto;
			width: 10px;
		}

		path {

			&:first-child {
				fill: $malibu;
			}
		}
	}
}

.p {
	font-size: 11/16+rem;
	line-height: 13/11+em;
	color: $dove-gray-1;
}