@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.radio {
	display: flex;

	label {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;

		.icon {
			position: relative;
			box-sizing: border-box;
			display: block;
			width: 20/16+rem;
			min-width: 20/16+rem;
			height: 20/16+rem;
			border-radius: 50%;
			border: 2/16+rem solid $silver-chalice;
			margin-right: .5rem;
			transition: border .25s;
		}

		&:hover {

			.icon {
				border-color: $conflower-blue;
			}
		}
	}

	input {
		display: none;

		&:checked + label {

			&:not(:hover) {

				.icon {
					border-color: #000;
				}
			}

			.icon {

				&:after {
					content: '';
					box-sizing: border-box;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: .5rem;
					height: .5rem;
					border-radius: 50%;
					background: #000;
					transition: background .25s;
				}
			}

			&:hover {

				.icon {

					&:after {
						background: $conflower-blue;
					}
				}
			}
		}
	}
}